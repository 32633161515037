export const coming_soon_departments = [
    "Scrap metal",
];

export const popularCategories = [
    {"department": "Electronics", "category": "Computers", "subcategory": "Cell Phones"},
    {"department": "Electronics", "category": "Cameras", "subcategory": "Camera Bodies"},
    {"department": "Electronics", "category": "Office Equipment", "subcategory": "Printers"},
    {"department": "Books", "category": "Children's Books", "subcategory": ""},
    {"department": "Electronics", "category": "Home Appliances", "subcategory": "DVD Players"}
];