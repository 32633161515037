import React from "react"
import {Card, Col, Divider, Input, Menu, Row} from 'antd';
import TitleSection from "../sections/titleSection"
import Fuse from 'fuse.js'

import {connect} from "react-redux";
import {categoryDrawerOpen, productsFiltered} from "../../redux/actions/productAction"
import {popularCategories} from '../../mock-data/configurations'

import "./search-bar.css";
import {makeHeaderVisible} from "../../redux/actions/headerStateAction";
import {navigate} from "gatsby";

import DotsFilter from "../../assets/icons/dotsfilter.svg";
import DotsNine from "../../assets/icons/dotsnine.svg";
import NoResultIMG from "../../assets/images/no_result.png";

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listItems: [],
            brandItem: [],
            input: props.productName ? props.productName : null,
            resultVisible: false,
            isMobileSearchTriggered: false,
            mobile: "search-bar-div-flex",
            searchInputClass: "search-bar-input",
            previousSelectedCategory: "",
            popularCategoriesVisible: props.popularCategoriesVisible ? props.popularCategoriesVisible : false,
            HomeSearchPadding: false,
            focused: false,
            clearDisplay: false,
            notBlurItem: true,

            speed: 100,
            speed2: 30,
            strChosen: "",
            placeholderIndex: 0,
            isRemoving: false,
            messages: [ "Find products to recycle", "apple macbook", "denim jeans", "philips airfryer", "textbook", "nikon camera", "frying pan"]
      
        };
        this.getSearchResults = this.getSearchResults.bind(this);
        this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
        this.onCategorySelectClick = this.onCategorySelectClick.bind(this);
        this.onSearchBackMobile = this.onSearchBackMobile.bind(this);
        this.handleSearchResultClose = this.handleSearchResultClose.bind(this);
        this.handleMobileSearchBarClosed = this.handleMobileSearchBarClosed.bind(this);
        this.checkForMobileSearchView = this.checkForMobileSearchView.bind(this);
        this.handlePopularCategoryClick = this.handlePopularCategoryClick.bind(this);
        this.generatePopulateCategories = this.generatePopulateCategories.bind(this);
        this.getMobileClosedIcon = this.getMobileClosedIcon.bind(this);
        this.onMouseEnterBlurItem = this.onMouseEnterBlurItem.bind(this);
        this.onMousLeaveBlurItem = this.onMousLeaveBlurItem.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.searchBarRef = React.createRef();
        this.searchBarPrefixRef = React.createRef();
        this.mobileInput = React.createRef();

        this.updatePlaceholder = this.updatePlaceholder.bind(this);
        this.sleep = this.sleep.bind(this);    
    }
    sleep(time){
      return new Promise((resolve)=>setTimeout(resolve,time))
    }
  
  
    updatePlaceholder() {
      if (this.state.isRemoving) {
        if (this.state.strChosen.length > 0) {
          let pause = 0;
          if (this.state.strChosen.length == this.state.messages[this.state.placeholderIndex].length)
          pause = 1500;
          this.sleep(pause).then(()=>{
            this.setState({strChosen: this.state.strChosen.substr(0, this.state.strChosen.length - 1)});
            setTimeout( this.updatePlaceholder, this.state.speed2 );                
          });
          return;
        }
        this.setState({isRemoving: false, placeholderIndex: this.state.placeholderIndex+1});
        if (this.state.placeholderIndex >= this.state.messages.length) {
          this.setState({placeholderIndex: 0});
        }
        setTimeout( this.updatePlaceholder, this.state.speed );
        return;
      }
      var message = this.state.messages[this.state.placeholderIndex];
      this.setState({strChosen: message.substr(0, this.state.strChosen.length + 1)});
      if (this.state.strChosen.length === message.length) {
        this.setState({isRemoving: true});
      }
      setTimeout( this.updatePlaceholder, this.state.isRemoving ? this.state.speed2 : this.state.speed );
    }
  
  
    onBlur() {
      if (this.state.notBlurItem) {
        this.setState({ focused: false, clearDisplay: false })
      }
    }

    onFocus() {
      this.setState({ focused: true, clearDisplay: true })
    }

    onMouseEnterBlurItem() {
      this.setState({ notBlurItem: false })
    }

    onMousLeaveBlurItem() {
      this.setState({ notBlurItem: true });
    }

    onCategorySelectClick() {
        this.props.dispatch(categoryDrawerOpen())
    }

    onSearchBackMobile() {
      this.setState({
          notBlurItem: true,
          clearDisplay: false,
          resultVisible: false,
          input: null,
          focused: false,
      });
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleSearchResultClose, false);
        if (this.searchBarRef.current.offsetWidth > 850) {
          document.addEventListener('scroll', this.handleSearchResultClose, false);
        }
        setTimeout( this.updatePlaceholder, this.state.speed ) ;
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleSearchResultClose, false);
        if (this.searchBarRef.current.offsetWidth > 850) {
          document.addEventListener('scroll', this.handleSearchResultClose, false);
        }
    }

    handleSearchResultClose(e) {
        if (this.searchBarRef.current && !this.searchBarRef.current.contains(e.target) && this.state.resultVisible) {
            this.setState({resultVisible: false})
        }
    }

    onClearSearch() {
      this.setState({
          input: "",
          focused: true,
          clearDisplay: true,
          notBlurItem: true,
      });
    }

    getSearchResults(input) {
        // search options
        const options = {
            isCaseSensitive: false,
            shouldSort: true,
            useExtendedSearch: true,
            threshold: 0.5,
            findAllMatches: true,
            keys: [
                "id",
                "Name",
                "Department"
            ]
        };

        let productDetails = Object.values(this.props.store.allProductsById);
        if (this.props.store.selectedCategory !== "") {
          productDetails = productDetails
          .filter((product) => {
            return product.SubCategory == this.props.store.selectedCategory;
          });
        }
        let brandslist = productDetails
        .filter((product) => {
          return product.Product.toLowerCase().replace("other ", "").startsWith(input.target.value.toLowerCase())
        })
        .map((product) => {
          if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
            return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
          } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
            return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
          } else {
            return false
          }
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index && value != "" ;
        })
        .slice(0, 4)
        .map((product, prodIndex) => {
          return {id: prodIndex, Name: product.toLowerCase()}
        });
        let brandsItemsLength = brandslist.length;
        let searchSubTitleLength = (brandsItemsLength == 0) ? 0 : 2;
        let mAX_SEARCH_RESULTS = 10-brandsItemsLength-searchSubTitleLength;
        // get the best match MAX_SEARCH_RESULTS results
        let listMenuItems = null;
        const fuse = new Fuse(this.props.store.productList, options);
        let filteredItems = fuse.search(input.target.value).slice(0, mAX_SEARCH_RESULTS);
        if (filteredItems.length === 0) {
            if (this.props.store.selectedCategory === "") {
                listMenuItems = [<div className="no_result_card">
                  <img src={NoResultIMG}/>
                  <span>No results?</span>
                  <span>Try browsing by category</span>
                </div>];
            } else {
                let otherItems = fuse.search("OTHER " + this.props.store.selectedCategory.split(" ")[0]).slice(0, 1);
                listMenuItems = otherItems.map((item) =>
                    <Menu.Item key={item["item"].id}>{item["item"].Name}</Menu.Item>);
                if (!(otherItems.length)) {
                  let otherItemID = Object.values(this.props.store.allProductsById)
                  .find((product) => {
                    return product.Product == "OTHER " + this.props.store.selectedCategory.toUpperCase()
                  }).ProductID;
                  listMenuItems = <Menu.Item key={otherItemID}>{"OTHER " + this.props.store.selectedCategory.toUpperCase()}</Menu.Item>;
                }
            }
        } else {
            listMenuItems = filteredItems.map((item, index) => {
              if (this.props.store.selectedCategory !== "") {
                let itemName = item["item"].Name;
                if (itemName.startsWith("\"")) itemName = itemName.replace("\"\"", "'").replace('"', "").replace("\"", "").replace("'", '"');
                var theProductName = itemName.toLowerCase();
                var searchPositionResult = theProductName.indexOf(input.target.value.toLowerCase());
                var inputValueLength = input.target.value.length;
                var theProductNameLength = itemName.length;

                let searchResultStart = itemName.slice(0, searchPositionResult);
                var searchResultMiddle = itemName.slice(searchPositionResult, searchPositionResult+inputValueLength);
                var searchResultEnd = itemName.slice(searchPositionResult+inputValueLength, theProductNameLength);
                return (<Menu.Item key={item["item"].id}><b>{searchResultStart}<span className="search_light">{searchResultMiddle ? searchResultMiddle : ""}</span>{searchResultMiddle ? searchResultEnd : ""}</b></Menu.Item>);
              } else {
                if (index < 3) {

                  let itemName = item["item"].Name;
                  if (itemName.startsWith("\"")) itemName = itemName.replace("\"\"", "'").replace('"', "").replace("\"", "").replace("'", '"');
                  var theProductName = itemName.toLowerCase();
                  var searchPositionResult = theProductName.indexOf(input.target.value.toLowerCase());
                  var inputValueLength = input.target.value.length;
                  var theProductNameLength = itemName.length;

                  let searchResultStart = itemName.slice(0, searchPositionResult);
                  var searchResultMiddle = itemName.slice(searchPositionResult, searchPositionResult+inputValueLength);
                  var searchResultEnd = itemName.slice(searchPositionResult+inputValueLength, theProductNameLength);

                  if (searchPositionResult != -1) {
                    return (<Menu.Item key={item["item"].id}><b>{searchResultStart}<span className="search_light">{searchResultMiddle ? searchResultMiddle : ""}</span>{searchResultMiddle ? searchResultEnd : ""}<span style={{color: "#1877F2", fontWeight: "normal"}}> in {item["item"].Department}</span></b></Menu.Item>);
                  }

                } else {
                  let searchResult = item["item"].Name;
                  if (searchResult.startsWith("\"")) searchResult = searchResult.replace("\"\"", "'").replace('"', "").replace("\"", "").replace("'", '"');
                  return (<Menu.Item key={item["item"].id}><b>{searchResult}</b></Menu.Item>);
                }
              }
            }
            )

            if (filteredItems.length < mAX_SEARCH_RESULTS) {
                if (this.props.store.selectedCategory === "") {
                    listMenuItems = [...listMenuItems]
                } else {
                    let otherItems = fuse.search("OTHER " + this.props.store.selectedCategory.split(" ")[0]).slice(0, 1);
                    let otherItemFound = filteredItems.find(function (item) {
                        return item.item.id === otherItems[0].item.id;
                    });
                    if (!otherItemFound) {
                      let otherItemCard = otherItems.map((item) =>
                        <div className="search_other_item_card">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#697386"/>
                          </svg>
                          <div>
                            <span>Search for your product brand or model</span>
                            <span>Select “Other” if the brand/model isn’t shown</span>
                          </div>
                        </div>);
                      let otherItem = otherItems.map((item) =>
                            <Menu.Item key={item["item"].id}>{item["item"].Name}</Menu.Item>);
                        listMenuItems = [otherItemCard, ...listMenuItems, otherItem]
                    }
                }
            }
        }
        this.setState({
            listItems: listMenuItems,
            input: input.target.value,
            resultVisible: true,
            brandItem: brandslist
        });


    }

    onMenuItemClicked(item) {
        if (item.key === "no_results_message" ||
            item.key === "no_results_search_suggestion" ||
            item.key === "insufficient_results_search_suggestion") {
            return
        }
        this.props.dispatch(makeHeaderVisible(true));
        this.setState({
            resultVisible: false,
            isMobileSearchTriggered: false,
            mobile: "search-bar-div-flex",
            input: this.props.store.allProductsById[item.key]["Product"]
        });
        if (this.props.store.allProductsById[item.key]["Premium"] === "1") {
          let productName = this.props.store.allProductsById[item.key]["Product"];
          if (productName.startsWith("\"")) productName = productName.replace("\"\"", "'").replace('"', "").replace("\"", "").replace("'", '"');
            navigate("/recycle", {
                state: {
                    productId: item.key,
                    productName: productName,
                    productInfo: this.props.store.allProductsById[item.key],
                },
            });
        } else {
            navigate("/quoteOther", {
                state: {
                    productId: item.key,
                    productName: this.props.store.allProductsById[item.key]["Product"],
                    productInfo: this.props.store.allProductsById[item.key],
                },
            });
        }


    }


    getSearchBackMobileSVG() {
      return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M15 6.99997H3.82998L8.70998 2.11997C9.09998 1.72997 9.09998 1.08997 8.70998 0.699971C8.31998 0.309971 7.68998 0.309971 7.29998 0.699971L0.70998 7.28997C0.31998 7.67997 0.31998 8.30997 0.70998 8.69997L7.29998 15.29C7.68998 15.68 8.31998 15.68 8.70998 15.29C9.09998 14.9 9.09998 14.27 8.70998 13.88L3.82998 8.99997H15C15.55 8.99997 16 8.54997 16 7.99997C16 7.44997 15.55 6.99997 15 6.99997Z" fill="#697386"/>
        </svg>
      );
    }

    getSearchSVG() {
        return (
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 11H11.71L11.43 10.73C12.63 9.33001 13.25 7.42002 12.91 5.39002C12.44 2.61002 10.12 0.390015 7.32001 0.0500152C3.09001 -0.469985 -0.469985 3.09001 0.0500152 7.32001C0.390015 10.12 2.61002 12.44 5.39002 12.91C7.42002 13.25 9.33001 12.63 10.73 11.43L11 11.71V12.5L15.25 16.75C15.66 17.16 16.33 17.16 16.74 16.75C17.15 16.34 17.15 15.67 16.74 15.26L12.5 11ZM6.50002 11C4.01002 11 2.00002 8.99002 2.00002 6.50002C2.00002 4.01002 4.01002 2.00002 6.50002 2.00002C8.99002 2.00002 11 4.01002 11 6.50002C11 8.99002 8.99002 11 6.50002 11Z" fill="#697386"/>
          </svg>
        );
    }

    getClearSearchSVG() {
        return (
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8 0.709971C13.41 0.319971 12.78 0.319971 12.39 0.709971L7.49997 5.58997L2.60997 0.699971C2.21997 0.309971 1.58997 0.309971 1.19997 0.699971C0.809971 1.08997 0.809971 1.71997 1.19997 2.10997L6.08997 6.99997L1.19997 11.89C0.809971 12.28 0.809971 12.91 1.19997 13.3C1.58997 13.69 2.21997 13.69 2.60997 13.3L7.49997 8.40997L12.39 13.3C12.78 13.69 13.41 13.69 13.8 13.3C14.19 12.91 14.19 12.28 13.8 11.89L8.90997 6.99997L13.8 2.10997C14.18 1.72997 14.18 1.08997 13.8 0.709971V0.709971Z" fill="#828282"/>
          </svg>
        );
    }

    renderSearchBarPrefix = () => {
      return (
        <div className="search_prefix">

          <div className="search-bar-prefix-span only-when-focus" ref={this.searchBarPrefixRef} onClick={this.onSearchBackMobile} onMouseEnter={this.onMouseEnterBlurItem} onMouseLeave={this.onMousLeaveBlurItem.bind(this)}>
              <div className="search-bar-prefix-span-icon">
                  {this.getSearchBackMobileSVG()}
              </div>
              <span className="search-all-label">{this.props.store.selectedCategory ? this.props.store.selectedCategory : "Departments"}</span>
              <Divider className="search-bar-divider " type="vertical"/>
          </div>


          <div className="search-bar-prefix-span not-when-focus" ref={this.searchBarPrefixRef} onClick={this.onCategorySelectClick}>

            <div className="search-bar-prefix-span-icon">
                      <div>
                        <DotsFilter />
                      </div>
            </div>
            <span className="search-all-label">{this.props.store.selectedCategory ? this.props.store.selectedCategory : "Departments"}</span>
            <Divider className="search-bar-divider preffix_diviser" type="vertical"/>
          </div>


        </div>
      )
    };


    renderSearchBarSuffix = () => {
      if (this.state.clearDisplay && this.state.input) {
        return (
            <span className="search-bar-suffix-span">
                <div className="search-bar-suffix-span-icon" onClick={this.onClearSearch}  onMouseEnter={this.onMouseEnterBlurItem} onMouseLeave={this.onMousLeaveBlurItem.bind(this)}>
                  {this.getClearSearchSVG()}
                </div>
            <Divider className="search-bar-divider suffix_diviser" type="vertical"/>
                <div className="search-bar-suffix-span-icon">
                    {this.getSearchSVG()}
                </div>
            </span>
        )
      } else  {
              return (
                <span className="search-bar-suffix-span">
                    <div className="search-bar-suffix-span-icon">
                        {this.getSearchSVG()}
                    </div>
                </span>
              )
            }
    };
    renderMobileSearchBar() {
        return (<div style={{display: "flex"}}>
            {this.renderSearchBarPrefix()}
            <Input className="search-bar-mobile-input" autocomplete="off" ref={this.mobileInput} value={this.state.input}
                   onChange={this.getSearchResults} onFocus={this.onFocus} onBlur={this.onBlur}/>
        </div>);
    }

    handleMobileSearchBarClosed() {
        this.props.dispatch(makeHeaderVisible(true));
        this.setState({isMobileSearchTriggered: false, mobile: "search-bar-div-flex"});
    }

    getMobileClosedIcon() {
        return (
            <svg onClick={this.handleMobileSearchBarClosed} width="30" height="30" viewBox="0 0 30 30" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.0483 0.670408C26.9523 -0.22347 28.418 -0.223469 29.3219 0.670409C30.2259 1.56429 30.2259 3.01355 29.3219 3.90743L3.95158 28.9943C3.0476 29.8882 1.58196 29.8882 0.677984 28.9943C-0.225995 28.1004 -0.225995 26.6512 0.677984 25.7573L26.0483 0.670408Z"
                    fill="#CAD2D7"/>
                <path
                    d="M29.322 26.0926C30.226 26.9865 30.226 28.4357 29.322 29.3296C28.418 30.2235 26.9524 30.2235 26.0484 29.3296L0.678059 4.24269C-0.225919 3.34882 -0.225919 1.89955 0.678059 1.00567C1.58204 0.111797 3.04768 0.111797 3.95165 1.00568L29.322 26.0926Z"
                    fill="#CAD2D7"/>
            </svg>

        );
    }

    getMobileViewSearch() {
        if (this.state.isMobileSearchTriggered) {
            return (
                <div className="search-bar-mobile-view">
                    <Card title={this.renderMobileSearchBar()}
                          extra={this.getMobileClosedIcon()}
                          style={{width: "100%", height: "100%"}}>
                        <Menu className="search-result-menu" onClick={this.onMenuItemClicked}>
                          {this.state.listItems}
                        </Menu>
                    </Card>
                </div>);

        }
    }

    checkForMobileSearchView() {
        if (this.searchBarRef.current.offsetWidth < 0) {
            this.props.dispatch(makeHeaderVisible(false));
            this.setState({isMobileSearchTriggered: true, mobile: "search-bar-div-none"})
        }
    }

    getResultPanelWidth() {
        if (this.searchBarPrefixRef && this.searchBarPrefixRef.current) {
            return this.searchBarPrefixRef.current.offsetWidth - 10;
        }
    }

    getResultPanelMarginTop() {
        if (this.searchBarRef) {
            let element = this.searchBarRef.current;
            let yPosition = 0;
            while (element) {
                yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
                element = element.offsetParent;
            }
            if (this.state.HomeSearchPadding) {
              let yOffset = this.searchBarRef.current.getBoundingClientRect().top;
              let yHeight = this.searchBarRef.current.getBoundingClientRect().height;
              return  yOffset+yHeight-125;
            } else {
              return yPosition > 79 ? 80 : this.searchBarRef.current.offsetWidth < 850 ? 150 : 160;
            }
        }
    }

    generatePopulateCategories() {
        let that = this;
        return popularCategories.map(function (item) {
            return <span onClick={that.handlePopularCategoryClick} department={item.department} category={item.category}
                         subcategory={item.subcategory}
                         className="popular-categories">{item.subcategory ? item.subcategory : item.category ? item.category : item.department}</span>
        });
    }

    handlePopularCategoryClick(e) {
        let selected = e.target;
        this.props.dispatch(productsFiltered(selected.getAttribute("department"), selected.getAttribute("category"),
            selected.getAttribute("subcategory")))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.isMobileSearchTriggered && this.state.isMobileSearchTriggered) {
            this.mobileInput.current.input.focus()
        }
    }

    render() {
        if (this.state.previousSelectedCategory !== this.props.store.selectedCategory) {
            this.state.listItems = null;
            this.state.input = null;
            this.state.previousSelectedCategory = this.props.store.selectedCategory;
        }

        return (
          <div className={this.state.HomeSearchPadding ? "search-bar-root search-bar-root-home " + (this.props.store.loggedIn ? "search-bar-root-member" : "") : "search-bar-root search-bar-root-nothome " + (this.props.store.loggedIn ? "search-bar-root-member" : "")} focused={(this.state.focused || this.state.resultVisible || this.state.clearDisplay ) ? "true" : ""} style={this.props.noMargin ? {marginTop: 0} : {}}>
            <div ref={this.searchBarRef} className={"search-bar-div " + this.state.mobile}>
                    <Row className={this.state.HomeSearchPadding ? "search-bar-parent-panel" : "search-bar-parent-panel search-bar-parent-header"}>
                        <Col span={24}>
                            {this.state.HomeSearchPadding ? <TitleSection
                              title="What will you recycle today?"
                              paragraph={"Search over 2,000 items that we buy"}
                            /> : ""}
                            <label id="for_search_input" style={{display:"none",position: "absolute", left: -100, top: -100}}>Search</label>
                            {this.renderSearchBarPrefix()}
                            <Input aria-labelledby="for_search_input" ref="search_bar_text_input" autocomplete="off" onClick={this.checkForMobileSearchView}
                                   onChange={this.getSearchResults}
                                   value={this.state.input}
                                   className={(this.state.focused || this.state.resultVisible) ? "search-bar-input search-bar-input-focus" : "search-bar-input"} size="large"
                                   prefix={this.renderSearchBarPrefix()}
                                   suffix={this.renderSearchBarSuffix()}
                                   id="search_input"
                                   onFocus={this.onFocus}
                                   onBlur={this.onBlur}
                                    placeholder={this.state.strChosen}
                                   />
                        </Col>
                    </Row>
                    <Row className="search-result-parent-panel">
                        {this.state.resultVisible && this.state.listItems.length !== 0 && this.state.input ?
                            <Card className="search-result"
                                  style={{marginLeft: this.getResultPanelWidth(), top: this.getResultPanelMarginTop()}}>
                                <Menu className="search-result-menu">
                                <div className="ant-menu-item search-result-menu-header">
                                  <span className="search-sub-title" onClick={this.onCategorySelectClick}>Browse by category</span>
                                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0.5L6.59 1.91L12.17 7.5H0V9.5H12.17L6.59 15.09L8 16.5L16 8.5L8 0.5Z" fill="#697386"/>
                                  </svg>
                                </div>
                                {
                                  this.state.brandItem.filter((brand) => {return brand.Name.includes(this.state.input.toLowerCase())}).length > 0
                                  && <div className="ant-menu-item search-sub-title-container"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.43 6.50001L8.95996 1.66001C8.66996 0.71001 7.32996 0.71001 7.04996 1.66001L5.56996 6.50001H1.11996C0.149959 6.50001 -0.250041 7.75001 0.539959 8.31001L4.17996 10.91L2.74996 15.52C2.45996 16.45 3.53996 17.2 4.30996 16.61L7.99996 13.81L11.69 16.62C12.46 17.21 13.54 16.46 13.25 15.53L11.82 10.92L15.46 8.32001C16.25 7.75001 15.85 6.51001 14.88 6.51001H10.43V6.50001Z" fill="#697386"/>
                                  </svg><span className="search-sub-title">
                                  Brands</span></div>
                                }
                                    {
                                      this.state.brandItem.filter((brand) => {
                                        return brand.Name.includes(this.state.input.toLowerCase());
                                      }).map((item) => {
                                        var theProductName = item.Name.toLowerCase();
                                        var searchPositionResult = theProductName.indexOf(this.state.input.toLowerCase());
                                        var inputValueLength = this.state.input.length;
                                        var theProductNameLength = item.Name.length;

                                        var searchResultStart = item.Name.toUpperCase().slice(0, searchPositionResult);
                                        var searchResultMiddle = item.Name.toUpperCase().slice(searchPositionResult, searchPositionResult+inputValueLength);
                                        var searchResultEnd = item.Name.toUpperCase().slice(searchPositionResult+inputValueLength, theProductNameLength);
                                        return (<Menu.Item key={item.id} onClick={() => {
                                          if (this.props.brandPage) {
                                            this.props.setBrand(item.Name.replace(/[^\w\s]/gi, '').replace(/\s/g, ''));
                                            navigate("/brand/" + item.Name.replace(/[^\w\s]/gi, '').replace(/\s/g, '')); this.onSearchBackMobile();
                                          } else {
                                            navigate("/brand/" + item.Name.replace(/[^\w\s]/gi, '').replace(/\s/g, '')); this.onSearchBackMobile();
                                          }
                                        }}><b>{searchResultStart}<span className="search_light">{searchResultMiddle ? searchResultMiddle : ""}</span>{searchResultMiddle ? searchResultEnd : ""}</b></Menu.Item>)
                                      })
                                    }
                                </Menu>
                                <Menu className="search-result-menu" onClick={this.onMenuItemClicked}>
                                    <div className="ant-menu-item search-sub-title-container"><svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14 4.5H12C12 2.29 10.21 0.5 8 0.5C5.79 0.5 4 2.29 4 4.5H2C0.9 4.5 0 5.4 0 6.5V18.5C0 19.6 0.9 20.5 2 20.5H14C15.1 20.5 16 19.6 16 18.5V6.5C16 5.4 15.1 4.5 14 4.5ZM6 8.5C6 9.05 5.55 9.5 5 9.5C4.45 9.5 4 9.05 4 8.5V6.5H6V8.5ZM8 2.5C9.1 2.5 10 3.4 10 4.5H6C6 3.4 6.9 2.5 8 2.5ZM12 8.5C12 9.05 11.55 9.5 11 9.5C10.45 9.5 10 9.05 10 8.5V6.5H12V8.5Z" fill="#697386"/>
                                      </svg><span className="search-sub-title" onClick={() => console.log(this.state.listItems)}>
                                      Products</span>
                                    </div>
                                    {this.state.listItems}
                                </Menu>
                            </Card> : ""}
                    </Row>
                </div>
                {this.getMobileViewSearch()}
                {this.state.popularCategoriesVisible ?
                    <Row className="popular-categories-root">
                        <Col xl={{span: 12, offset: 6}} lg={{span: 16, offset: 4}} md={{span: 20, offset: 2}}
                             style={{display: "inline-grid", textAlign: "center"}}>
                            <div>
                                <span className="popular-categories-title">POPULAR:</span>
                                {this.generatePopulateCategories()}
                            </div>
                        </Col>
                    </Row> : ""}
            </div>
        );
    }
}

function readProductsList(storeState) {
    let store = {
        productList: storeState.product.filteredProducts,
        allProductsById: storeState.product.productsIndexedById,
        selectedCategory: storeState.product.selectedProductCategory,
        loggedIn: storeState.user.isLoggedIn
    };
    return {store};
}

export default connect(readProductsList)(SearchBar);
